// Function for converting a px based font-size to rem.
@use "sass:math";

@function calculateRem($size) {
	@return math.div($size, 16px) * 1rem;
}

body {

	* {
		--font-family: Roboto, Helvetica, Arial, sans-serif;

		//todo check if breakpoint is correct
		--mobile-breakpoint-size: 1024px;
		// design system colors

		// primary color dark
		--primary-color-dark-50: #64587A;
		--primary-color-dark-100: #5A506F;
		--primary-color-dark-200: #514864;
		--primary-color-dark-300: #484058;
		--primary-color-dark-400: #3F374D;
		--primary-color-dark-500: #352F41;
		--primary-color-dark-600: #2C2736;
		--primary-color-dark-700: #231F2B;
		--primary-color-dark-800: #19171F;
		--primary-color-dark-900: #100E14;

		--primary-color-dark-transparent-10: rgba(44, 39, 54, 0.1);
		--primary-color-dark-transparent-20: rgba(44, 39, 54, 0.2);
		--primary-color-dark-transparent-40: rgba(44, 39, 54, 0.4);
		--primary-color-dark-transparent-60: rgba(44, 39, 54, 0.6);
		--primary-color-dark-transparent-80: rgba(44, 39, 54, 0.8);

		// primary color light
		--primary-color-light-50: #FFFFFF;
		--primary-color-light-100: #F6F9FB;
		--primary-color-light-200: #E9F0F4;
		--primary-color-light-300: #DCE7ED;
		--primary-color-light-400: #CFDEE6;
		--primary-color-light-500: #BED5E2;
		--primary-color-light-600: #C5D3DB;
		--primary-color-light-700: #C5D3DB;
		--primary-color-light-800: #C9D2D7;
		--primary-color-light-900: #CCD1D4;

		--primary-color-light-transparent-10: rgba(207, 222, 230, 0.1);
		--primary-color-light-transparent-20: rgba(207, 222, 230, 0.2);
		--primary-color-light-transparent-40: rgba(207, 222, 230, 0.4);
		--primary-color-light-transparent-60: rgba(207, 222, 230, 0.6);
		--primary-color-light-transparent-80: rgba(207, 222, 230, 0.8);

		// accent color
		--accent-color-50: #7FCFCB;
		--accent-color-100: #63C4C0;
		--accent-color-200: #47B9B4;
		--accent-color-300: #41ABA7;
		--accent-color-400: #368F8B;
		--accent-color-500: #31817D;
		--accent-color-600: #2B736F;
		--accent-color-700: #266461;
		--accent-color-800: #205654;
		--accent-color-900: #1B4846;

		--accent-color-transparent-10: rgba(54, 143, 139, 0.1);
		--accent-color-transparent-20: rgba(54, 143, 139, 0.2);
		--accent-color-transparent-40: rgba(54, 143, 139, 0.4);
		--accent-color-transparent-60: rgba(54, 143, 139, 0.6);
		--accent-color-transparent-80: rgba(54, 143, 139, 0.8);

		// call to action color
		--call-to-action-color-50: #FFA989;
		--call-to-action-color-100: #FF8D62;
		--call-to-action-color-200: #FF703B;
		--call-to-action-color-300: #FF6227;
		--call-to-action-color-400: #FF4500;
		--call-to-action-color-500: #EB4000;
		--call-to-action-color-600: #D83A00;
		--call-to-action-color-700: #C43500;
		--call-to-action-color-800: #B13000;
		--call-to-action-color-900: #9D2A00;

		--call-to-action-color-transparent-10: rgba(255, 69, 0, 0.1);
		--call-to-action-color-transparent-20: rgba(255, 69, 0, 0.2);
		--call-to-action-color-transparent-40: rgba(255, 69, 0, 0.4);
		--call-to-action-color-transparent-60: rgba(255, 69, 0, 0.6);
		--call-to-action-color-transparent-80: rgba(255, 69, 0, 0.8);


		//box-shadows
		--primary-color-dark-box-shadow: 0px 6px 8px 2px rgba(86, 77, 106, 0.2);
		--primary-color-light-box-shadow: 0px 6px 4px 2px rgba(224, 224, 224, 0.2);
		--accent-color-box-shadow: 0px 6px 8px 2px rgba(112, 255, 222, 0.2);
		--call-to-action-color-box-shadow: 0px 6px 12px 2px rgba(184, 49, 0, 0.2);


		// Standard colors
		--primary-color-dark-default: var(--primary-color-dark-600);
		--primary-color-light-default: var(--primary-color-light-300);
		--accent-color-default: var(--accent-color-400);
		--call-to-action-color-default: var(--call-to-action-color-400);

		// util colors dark
		--neutral-color-dark: var(--primary-color-light-400);
		--good-color-dark: var(--accent-color-100);
		--warning-color-dark: var(--call-to-action-color-200);
		--error-color-dark: var(--call-to-action-color-600);

		// util colors light
		--neutral-color-light: var(--primary-color-dark-800);
		--good-color-light: var(--accent-color-200);
		--warning-color-light: var(--call-to-action-color-200);
		--error-color-light: var(--call-to-action-color-600);

		// design system text variables
		//default
		--text-letter-spacing: 1px;
		--text-font-feature-setting: 'pnum' on, 'lnum' on;

		//body
		--body-text-font-weight: 400;
		--body-text-font-size-rem: #{calculateRem(16px)};
		--body-text-font-size-fallback-px: 16px;
		--body-text-line-height: #{calculateRem(27px)};
		--body-text-letter-spacing: var(--text-letter-spacing);
		--body-text-font-feature-settings: var(--text-font-feature-setting);

		//h1 - mobile
		--h1-text-font-weight: 500;
		--h1-text-font-size-rem: #{calculateRem(26px)};
		--h1-text-font-size-fallback-px: 26px;
		--h1-text-line-height: #{calculateRem(41px)};
		--h1-text-letter-spacing: var(--text-letter-spacing);
		--h1-text-font-feature-settings: var(--text-font-feature-setting);

		//h2 - mobile
		--h2-text-font-weight: 500;
		--h2-text-font-size-rem: #{calculateRem(20px)};
		--h2-text-font-size-fallback-px: 20px;
		--h2-text-line-height: #{calculateRem(33px)};
		--h2-text-letter-spacing: var(--text-letter-spacing);
		--h2-text-font-feature-settings: var(--text-font-feature-setting);

		//h3 - mobile todo define
		--h3-text-font-weight: 500;
		--h3-text-font-size-rem: #{calculateRem(18px)};
		--h3-text-font-size-fallback-px: 18px;
		--h3-text-line-height: #{calculateRem(30px)};
		--h3-text-letter-spacing: var(--text-letter-spacing);
		--h3-text-font-feature-settings: var(--text-font-feature-setting);

		//h4 - mobile todo define
		--h4-text-font-weight: 500;
		--h4-text-font-size-rem: #{calculateRem(18px)};
		--h4-text-font-size-fallback-px: 18px;
		--h4-text-line-height: #{calculateRem(30px)};
		--h4-text-letter-spacing: var(--text-letter-spacing);
		--h4-text-font-feature-settings: var(--text-font-feature-setting);

		//content
		--content-text-font-weight: 400;
		--content-text-font-size-rem: #{calculateRem(14px)};
		--content-text-font-size-fallback-px: 14px;
		--content-text-line-height: #{calculateRem(32px)};
		--content-text-letter-spacing: var(--text-letter-spacing);
		--content-text-font-feature-settings: var(--text-font-feature-setting);

		//caption
		--caption-text-font-weight: 300;
		--caption-text-font-size-rem: #{calculateRem(16px)};
		--caption-text-font-size-fallback-px: 16px;
		--caption-text-line-height: #{calculateRem(27px)};
		--caption-text-letter-spacing: var(--text-letter-spacing);
		--caption-text-font-feature-settings: var(--text-font-feature-setting);

		//subscript
		--subscript-text-font-weight: 100;
		--subscript-text-font-size-rem: #{calculateRem(13px)};
		--subscript-text-font-size-fallback-px: 13px;
		--subscript-text-line-height: #{calculateRem(23px)};
		--subscript-text-letter-spacing: var(--text-letter-spacing);
		--subscript-text-font-feature-settings: var(--text-font-feature-setting);

		//subtitle 1
		--subtitle1-text-font-weight: 300;
		--subtitle1-text-font-size-rem: #{calculateRem(33px)};
		--subtitle1-text-font-size-fallback-px: 33px;
		--subtitle1-text-line-height: #{calculateRem(51px)};
		--subtitle1-text-letter-spacing: var(--text-letter-spacing);
		--subtitle1-text-font-feature-settings: var(--text-font-feature-setting);

		//subtitle 2
		--subtitle2-text-font-weight: 300;
		--subtitle2-text-font-size-rem: #{calculateRem(20px)};
		--subtitle2-text-font-size-fallback-px: 20px;
		--subtitle2-text-line-height: #{calculateRem(33px)};
		--subtitle2-text-letter-spacing: var(--text-letter-spacing);
		--subtitle2-text-font-feature-settings: var(--text-font-feature-setting);

		//chat message user
		--chat-message-user-font-weight: 700;
		--chat-message-user-font-size-rem: #{calculateRem(16px)};
		--chat-message-user-font-size-fallback-px: 16px;
		--chat-message-user-line-height: #{calculateRem(27px)};
		--chat-message-user-letter-spacing: var(--text-letter-spacing);
		--chat-message-user-font-feature-settings: var(--text-font-feature-setting);

		//desktop text overwrites
		@media screen and (min-width: var(--mobile-breakpoint-size)) {
			//h1 - desktop
			--h1-text-font-weight: 500;
			--h1-text-font-size-rem: #{calculateRem(42px)};
			--h1-text-font-size-fallback-px: 42px;
			--h1-text-line-height: #{calculateRem(64px)};
			--h1-text-letter-spacing: var(--text-letter-spacing);
			--h1-text-font-feature-settings: var(--text-font-feature-setting);

			//h2 - desktop
			--h2-text-font-weight: 500;
			--h2-text-font-size-rem: #{calculateRem(33px)};
			--h2-text-font-size-fallback-px: 33px;
			--h2-text-line-height: #{calculateRem(51px)};
			--h2-text-letter-spacing: var(--text-letter-spacing);
			--h2-text-font-feature-settings: var(--text-font-feature-setting);

			//h3 - desktop
			--h3-text-font-weight: 500;
			--h3-text-font-size-rem: #{calculateRem(26px)};
			--h3-text-font-size-fallback-px: 26px;
			--h3-text-line-height: #{calculateRem(41px)};
			--h3-text-letter-spacing: var(--text-letter-spacing);
			--h3-text-font-feature-settings: var(--text-font-feature-setting);

			//h4 - dekstop
			--h4-text-font-weight: 500;
			--h4-text-font-size-rem: #{calculateRem(20px)};
			--h4-text-font-size-fallback-px: 20px;
			--h4-text-line-height: #{calculateRem(33px)};
			--h4-text-letter-spacing: var(--text-letter-spacing);
			--h4-text-font-feature-settings: var(--text-font-feature-setting);
		}
	}

}


// design system texts
body {
	font-weight: var(--body-text-font-weight);
	font-size: var(--body-text-font-size-fallback-px);
	font-size: var(--body-text-font-size-rem);
	line-height: var(--body-text-line-height);
	letter-spacing: var(--body-text-letter-spacing);
	font-feature-settings: var(--body-text-font-feature-settings);

	h1 {
		font-weight: var(--h1-text-font-weight);
		font-size: var(--h1-text-font-size-fallback-px);
		font-size: var(--h1-text-font-size-rem);
		line-height: var(--h1-text-line-height);
		letter-spacing: var(--h1-text-letter-spacing);
		font-feature-settings: var(--h1-text-font-feature-settings);
	}

	h2 {
		font-weight: var(--h2-text-font-weight);
		font-size: var(--h2-text-font-size-fallback-px);
		font-size: var(--h2-text-font-size-rem);
		line-height: var(--h2-text-line-height);
		letter-spacing: var(--h2-text-letter-spacing);
		font-feature-settings: var(--h2-text-font-feature-settings);
	}

	h3 {
		font-weight: var(--h3-text-font-weight);
		font-size: var(--h3-text-font-size-fallback-px);
		font-size: var(--h3-text-font-size-rem);
		line-height: var(--h3-text-line-height);
		letter-spacing: var(--h3-text-letter-spacing);
		font-feature-settings: var(--h3-text-font-feature-settings);
	}

	h4 {
		font-weight: var(--h4-text-font-weight);
		font-size: var(--h4-text-font-size-fallback-px);
		font-size: var(--h4-text-font-size-rem);
		line-height: var(--h4-text-line-height);
		letter-spacing: var(--h4-text-letter-spacing);
		font-feature-settings: var(--h4-text-font-feature-settings);
	}

	// this class should only be used when the default body text is not applied for example in buttons
	.body-text {
		font-weight: var(--body-text-font-weight);
		font-size: var(--body-text-font-size-fallback-px);
		font-size: var(--body-text-font-size-rem);
		line-height: var(--body-text-line-height);
		letter-spacing: var(--body-text-letter-spacing);
		font-feature-settings: var(--body-text-font-feature-settings);
	}

	.body-text-condensed {
		font-family: 'Saira Extra Condensed', sans-serif;
		font-weight: var(--body-text-font-weight);
		font-size: var(--body-text-font-size-fallback-px);
		font-size: var(--body-text-font-size-rem);
		line-height: var(--body-text-line-height);
		letter-spacing: var(--body-text-letter-spacing);
		font-feature-settings: var(--body-text-font-feature-settings);
	}

	.content-text {
		font-weight: var(--content-text-font-weight);
		font-size: var(--content-text-font-size-fallback-px);
		font-size: var(--content-text-font-size-rem);
		line-height: var(--content-text-line-height);
		letter-spacing: var(--content-text-letter-spacing);
		font-feature-settings: var(--content-text-font-feature-settings);
	}

	.caption {
		font-weight: var(--caption-text-font-weight);
		font-size: var(--caption-text-font-size-fallback-px);
		font-size: var(--caption-text-font-size-rem);
		line-height: var(--caption-text-line-height);
		letter-spacing: var(--caption-text-letter-spacing);
		font-feature-settings: var(--caption-text-font-feature-settings);
	}

	.subscript {
		font-weight: var(--subscript-text-font-weight);
		font-size: var(--subscript-text-font-size-fallback-px);
		font-size: var(--subscript-text-font-size-rem);
		line-height: var(--subscript-text-line-height);
		letter-spacing: var(--subscript-text-letter-spacing);
		font-feature-settings: var(--subscript-text-font-feature-settings);
	}

	.subtitle-1 {
		font-weight: var(--subtitle1-text-font-weight);
		font-size: var(--subtitle1-text-font-size-fallback-px);
		font-size: var(--subtitle1-text-font-size-rem);
		line-height: var(--subtitle1-text-line-height);
		letter-spacing: var(--subtitle1-text-letter-spacing);
		font-feature-settings: var(--subtitle1-text-font-feature-settings);
	}

	.subtitle-2 {
		font-weight: var(--subtitle2-text-font-weight);
		font-size: var(--subtitle2-text-font-size-fallback-px);
		font-size: var(--subtitle2-text-font-size-rem);
		line-height: var(--subtitle2-text-line-height);
		letter-spacing: var(--subtitle2-text-letter-spacing);
		font-feature-settings: var(--subtitle2-text-font-feature-settings);
	}
}
