/* You can add global styles to this file, and also import other style files */
@import "./assets/styling/variable.scss";
@import "./assets/styling/material.scss";
@import "./assets/styling/mixins.scss";
@import "./assets/styling/design-system.scss";

html {
	height: 100%;
}

body {
	height: 100%;
	min-height: 100%;
	margin: 0;
	font-family: var(--font-family);
	//margin-top: constant(safe-area-inset-top);
	//margin-top: env(safe-area-inset-top);
	//margin-bottom: calc(4px + env(safe-area-inset-top));

	* {
		color: var(--primary-text-color);
		box-sizing: border-box;
		font-family: var(--font-family);
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-moz-tap-highlight-color: rgba(0, 0, 0, 0);
	}
}

button {
	cursor: pointer;
}

div.cdk-overlay-backdrop {
	background: var(--modal-backdrop-color, rgba(255, 255, 255, 0.1));
}

mat-dialog-container.mat-dialog-container {
	border-radius: 8px;
	background: var(--dialog-default-background);
}

.no-padding mat-dialog-container.mat-dialog-container {
	padding: 0;
}

.left-icon-button {
	width: 100%;
	display: grid;
	grid-template-columns: 40px auto;
	margin-bottom: 8px;
	border: none;
	border-radius: var(--default-app-border-radius);
	height: 44px;

	.icon {
		margin: auto;
	}

	.text {
		text-align: center;
		padding-right: 40px;
		margin: auto 0;
		font-weight: var(--body-text-font-weight);
		font-size: var(--body-text-font-size-fallback-px);
		font-size: var(--body-text-font-size-rem);
		line-height: var(--body-text-line-height);
		letter-spacing: var(--body-text-letter-spacing);
		font-feature-settings: var(--body-text-font-feature-settings);
	}
}

.text-button {
	width: 100%;
	margin-bottom: 8px;
	border: none;
	border-radius: var(--default-app-border-radius);
	height: 44px;
	text-align: center;
	font-size: var(--button-font-size, 16px);
}

.dark-button {
	color: var(--dark-button-color);

	.text {
		color: var(--dark-button-color);
	}
}

.light-button {
	color: var(--light-button-color);

	.text {
		color: var(--light-button-color);
	}
}

.primary-button {
	background-color: var(--call-to-action-color-default);
}

button.icon-button {
	background: none;
	padding: 0;
	border: none;
	cursor: pointer;

	mat-icon {
		color: var(--header-button-color);
	}

}

.card {
	border-radius: var(--card-border-radius, var(--default-app-border-radius));
	background-color: var(--card-background-color, var(--secundairy-background-color));
	padding: var(--card-top-padding) var(--card-side-padding) var(--card-bottom-padding);
	color: var(--card-font-color);
	font-weight: var(--card-font-weight);
	font-style: var(--card-font-style);
	font-size: var(--card-font-size);
}

mat-icon.outline {
	font-family: 'Material Icons Outlined';
}

::-webkit-calendar-picker-indicator {
	filter: invert(1);
}

a.anchor-button {
	line-height: var(--anchor-button-height, 40px);
	display: block;
}

// todo remove this when fixed in tsng
snack-bar-container.level-default {
	mat-icon {
		color: var(--tsng-snackbar-color-default);
	}

	span {
		color: var(--tsng-snackbar-color-default);
	}
}

snack-bar-container.level-info {
	mat-icon {
		color: var(--tsng-snackbar-color-info);
	}

	span {
		color: var(--tsng-snackbar-color-info);
	}
}


snack-bar-container.level-warning {
	mat-icon {
		color: var(--tsng-snackbar-color-warning);
	}

	span {
		color: var(--tsng-snackbar-color-warning);
	}
}

snack-bar-container.level-error {
	mat-icon {
		color: var(--tsng-snackbar-color-error);
	}

	span {
		color: var(--tsng-snackbar-color-error);
	}
}

snack-bar-container.level-success {
	mat-icon {
		color: var(--tsng-snackbar-color-success);
	}

	span {
		color: var(--tsng-snackbar-color-success);
	}
}

tsng-input.create-message-input {
	* {
		--tsng-input-background-color: transparent;
		--tsng-label-padding: 0;
	}
}

profile-picture-upload {
	button.icon-button.change-image-button {
		background-color: var(--call-to-action-color-default);
	}
}

tsng-checkbox.email-list-is-admin-checkbox {
	--tsng-checkbox-border: 1px solid var(--primary-color-light-50);

	label {
		padding: 0;
	}
}

.group-settings-list-item {
	margin-bottom: 1px;
	display: flex;
	height: var(--group-member-list-item-height, 48px);
	background-color: var(--group-member-list-item-background-color);
	grid-template-areas: "image displayName role";
	grid-template-columns: 48px auto auto;
	align-items: center;
	padding: 0 var(--default-page-side-padding);
	border: none;
	width: 100%;
	text-align: unset;
	justify-content: space-between;
}

mat-dialog-container {
	max-width: 95vw !important;
	min-width: 300px;
	width: 100%;
}

discover-page {
	.tile-section {
		.wrapper {
			header {
				@media screen and (min-width: 600px) {
					bottom: 0;
				}
			}
		}
	}
}

tile-section-detail-page {
	.page-content {
		app-tile {
			a {
				header {
					@media screen and (min-width: 600px) {
						bottom: 4px !important;
					}
				}
			}
		}
	}
}

@keyframes shine-lines {
	0% {
		transform: translateX(-100%);
	}
	40%, 100% {
		transform: translateX(100%);
	}
}


// really material :/
.horizontal-virtual-scroll .cdk-virtual-scroll-content-wrapper {
	display: flex;
	flex-direction: row;
}

button.menu-item {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.material-icons-outlined {
	font-family: 'Material Icons Outlined';
}

.ngx-ptr-animation .ngx-ptr-path {
	animation: ngx-ptr-dash 1.4s ease-in-out infinite,
	ngx-ptr-colors-override 5.6s ease-in-out infinite !important;
}

@keyframes ngx-ptr-colors-override {
	0% {
		stroke: var(--primary-color);
	}
	100% {
		stroke: var(--primary-color);
	}
}

.bands-container {
	padding-top: 16px;
}

discover-page {
	discover-pull-to-refresh {
		.ngx-ptr-str-container {
			height: calc(100% - 56px) !important;
			min-height: calc(100% - 56px) !important;
		}
	}
}
