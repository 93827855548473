body {
	--font-family: Roboto, Helvetica, Arial, sans-serif;

	* {
		// Put the global css variables here
		--default-page-bottom-margin: 100px;
		--toolbar-height: 56px;
		--toolbar-padding: 12px 16px;
		--default-page-side-padding: 16px;
		--default-page-top-padding: 16px;
		--page-padding: var(--default-page-top-padding) var(--default-page-side-padding) 0;
		--bottom-nav-height: 64px;
		--bottom-nav-icon-size: 32px;
		--home-button-icon-size: 42px;
		--tile-band-padding: 0 16px;
		--tile-band-title-margin: 8px 16px;
		--tile-title-font-size: 16px;
		--tile-title-font-weight: normal;
		--bookmark-color: var(--primary-color-light-900);
		--bookmark-activity-color: var(--call-to-action-color-default);
		--bookmark-keyword-color: var(--accent-color-default);
		--tile-image-border-radius: 8px;
		--tile-text-shadow: var(--primary-color-dark-box-shadow);
		--tile-text-background-height: 60px;
		--tile-text-background:  linear-gradient(
				180deg, rgba(100, 88, 122, 0.01) 0%, rgba(100, 88, 122, 0.02) 3.3%, rgba(44, 39, 54, 0.2) 31.42%, rgba(44, 39, 54, 0.4) 57.47%, rgba(44, 39, 54, 0.6) 84.03%), rgba(44, 39, 54, 0.1);
		--default-app-border-radius: 5px;
		--default-between-items-margin: 32px;
		--facebook-login-button-color: #3B5998;
		--google-login-button-color: #FFFFFF;
		--email-login-button-color: #DB4437;
		--dark-button-color: var(--primary-color-light-50);
		--light-button-color: var(--primary-color-dark-default);
		--tsng-input-border-radius: 6px;
		--tsng-label-padding: 3px 0 8px;
		--tsng-label-font-weight: normal;
		--tsng-label-font-size: 16px;
		--card-top-padding: 16px;
		--card-side-padding: 16px;
		--card-bottom-padding: 16px;
		--card-font-weight: 300;
		--card-font-style: normal;
		--card-font-size: 0.95rem;
		--specification-item-font-weight: normal;
		--specification-item-font-size: 0.85rem;
		--dark-divider-color: var(--primary-color-dark-default);
		--activy-card-margin-bottom: 4px;
		--chat-message-padding: 8px;
		--chat-message-margin: 16px;
		--chat-message-indent-margin: 32px;
		--account-header-text-margin: 16px;
		--account-header-image-size: 32px;
		--account-menu-border-radius: 8px;
		--add-group-tile-icon-size: 48px;
		--user-group-list-grid-gap: 16px 68px;
		--group-settings-page-toolbar-height: 94px;
		--group-settings-toolbar-image-size: 64px;
		--group-header-sub-text-margin-top: -8px;
		--group-settings-footer-top-padding: 42px;
		--group-settings-terms-margin: 24px;
		--group-settings-margin-bottom: 16px;
		--home-button-size: 72px;

		--tsng-change-email-gutter-width: 16px;
		--tsng-change-email-submit-font-size: var(--h4-text-font-size-rem);
		--tsng-change-email-submit-padding: 8px;
		--tsng-change-email-submit-font-weight: var(--h4-text-font-weight);
		--tsng-change-email-submit-border-radius: 8px;
		--tsng-change-email-submit-border: 0;
		--tsng-change-email-submit-background-color: var(--call-to-action-color-default);
		--tsng-change-email-submit-color: var(--primary-color-light-50);

		--tsng-change-password-gutter-width: 16px;
		--tsng-change-password-submit-font-size: var(--h4-text-font-size-rem);
		--tsng-change-password-submit-padding: 8px;
		--tsng-change-password-submit-font-weight: var(--h4-text-font-weight);
		--tsng-change-password-submit-border-radius: 8px;
		--tsng-change-password-submit-border: 0;
		--tsng-change-password-submit-background-color: var(--call-to-action-color-default);
		--tsng-change-password-submit-color: var(--primary-color-light-50);

		--skeleton-animation-duration: 3.5s;
		//--default-skeleton-background: linear-gradient(90deg, var(--primary-color-light-transparent-10) 0, var(--primary-color-light-transparent-20) 50%, var(--primary-color-light-transparent-10) 100%);
		--default-skeleton-background: linear-gradient(90deg,  rgba(0,0,0,0) 0%,  var(--primary-color-light-transparent-20) 50%,  rgba(0,0,0,0) 100%);
	}

	&.dark-theme * {
		// Put the dark theme css variables here
		--secundairy-background-color: var(--primary-color-dark-default);
		--background: linear-gradient(180deg, #0A0310 0%, #2C2736 100%), #0A0310;
		--bottom-nav-background-color: var(--primary-color-dark-800);
		--bottom-nav-item-color: var(--primary-color-light-50);
		--bottom-nav-item-active-color: var(--primary-color-light-50);
		--home-button-background-color: var(--call-to-action-color-default);
		--primary-text-color: var(--primary-color-light-50);
		--toolbar-logo-color: var(--primary-color-light-50);
		--register-button-border: 1px solid var(--primary-color-light-50);
		--tsng-input-border: 1px solid var(--primary-color-light-default);
		--tsng-input-background-color: var(--primary-color-dark-default);
		--tsng-input-text-color: #DADADA;
		--tsng-input-invalid-text-color: #DADADA;
		--tsng-input-hover-background-color: var(--primary-color-dark-default);
		--tsng-input-focus-background-color: var(--primary-color-dark-default);
		--tsng-label-color: var(--primary-color-light-default);
		--tsng-checkbox-checkmark-color: var(--call-to-action-color-default);
		--card-font-color: var(--primary-color-light-default);
		--specification-item-icon-color: var(--accent-color-50);
		--specification-item-secundairy-icon-color: var(--call-to-action-color-default);
		--toolbar-background: #0A0310;
		--alternative-toolbar-background-color: var(--primary-color-dark-500);
		--group-member-list-item-background-color: var(--primary-color-dark-500);
		--chat-message-send-background-color: var(--accent-color-transparent-40);
		--chat-message-received-background-color: var(--accent-color-transparent-20);
		--chat-message-received-user-color: var(--accent-color-50);
		--account-menu-background-color: var(--primary-color-dark-900);
		--account-menu-divider: 1px solid var(--primary-color-dark-200);
		--modal-backdrop-color: var(--primary-color-dark-transparent-60);
		--add-group-tile-background-color: var(--call-to-action-color-default);
		--privacy-statement-link-color: var(--call-to-action-color-default);
		--login-message-login-text-color: var(--call-to-action-color-default);
		--tag-item-background-color: var(--call-to-action-color-default);
		--chat-detail-send-button-background-color: var(--call-to-action-color-default);
		--interest-grid-icon-color: var(--accent-color-50);
		--interest-grid-icon-text-shadow: var(--accent-color-default);
		--chat-list-unread-group-background-color: var(--accent-color-transparent-20);
		--chat-list-unread-group-name-color: var(--call-to-action-color-default);
		--chat-list-sent-message-color: var(--primary-color-light-200);
		--finish-registration-radio-checked-color: var(--call-to-action-color-default);
		--decline-group-invite-color: var(--call-to-action-color-default);
		--group-settings-list-item-subscript-colorm: var(--primary-color-light-200);
		--spash-screen-background-color: var(--primary-color-dark-900);

		--dialog-default-background: var(--primary-color-dark-900);
		--tsng-snackbar-background-color-default: var(--neutral-color-dark);
		--tsng-snackbar-color-default: var(--primary-color-dark-default);
		--tsng-snackbar-background-color-info: var(--neutral-color-dark);
		--tsng-snackbar-color-info: var(--primary-color-dark-default);
		--tsng-snackbar-background-color-warning: var(--warning-color-dark);
		--tsng-snackbar-color-warning: var(--primary-color-dark-default);
		--tsng-snackbar-background-color-error: var(--error-color-dark);
		--tsng-snackbar-color-error: var(--primary-color-dark-default);
		--tsng-snackbar-background-color-success: var(--good-color-dark);
		--tsng-snackbar-color-success: var(--primary-color-dark-default);
	}

	&.light-theme * {
		// Put the light theme css variables here
		--secundairy-background-color: var(--accent-color-default);
		--background-color: var(--primary-color-light-default);
		--bottom-nav-item-color: var(--primary-color-dark-default);
		--bottom-nav-item-active-color: var(--primary-color-dark-default);
		--primary-text-color: var(--primary-color-dark-900);
		--toolbar-logo-color: var(--call-to-action-color-default);

		--tsng-snackbar-background-color-default: var(--neutral-color-light);
		--tsng-snackbar-color-default: var(--primary-color-light-default);
		--tsng-snackbar-background-color-info: var(--neutral-color-light);
		--tsng-snackbar-color-info: var(--primary-color-light-default);
		--tsng-snackbar-background-color-warning: var(--warning-color-light);
		--tsng-snackbar-color-warning: var(--primary-color-light-default);
		--tsng-snackbar-background-color-error: var(--error-color-light);
		--tsng-snackbar-color-error: var(--primary-color-light-default);
		--tsng-snackbar-background-color-success: var(--good-color-light);
		--tsng-snackbar-color-success: var(--primary-color-light-default);
	}
}
